





















































// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// components
import List from '@/components/list/List.vue';
import { OrgModel } from '@/types/org';
import { RequestConfig } from '@/types/list';
import Btn from '@/components/buttons/Btn.vue';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import AppModal from '@/components/AppModal.vue';
import InfoBanner from '@/components/banners/InfoBanner.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';
import ListTooltip from '@/components/list/ListTooltip.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ManageUsersFromRole',
  components: {
    ListTooltip,
    EditBtn,
    InfoBanner,
    Btn,
    List,
    AppModal,
  },
  props: {
    role: Object,
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    roleId(): string {
      return this.$route.params.id;
    },
    requestConfig(): RequestConfig {
      return {
        url: `/org/${this.org.id}/user/info`,
      };
    },
  },
  data() {
    return {
      roleUsers: [] as string[],
      extraColumns: [
        {
          name: 'title',
          label: 'Title',
          field: 'title',
          align: 'center',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    isNotInRole(id: string): boolean {
      return this.roleUsers.find((user: string) => user === id) === undefined;
    },
    async open() {
      await this.loadUsersForRole();
      await (this.$refs.modal as any).open();
    },
    async addUserToRole(userId: string, role: number) {
      try {
        await ApiService.post(`/org/${this.org.id}/role/assign`, { role, users: [userId] });
        notify.success('Users added to the role');
        await this.loadUsersForRole();
        await (this.$refs.list as any).loadRows();
        this.$emit('submit');
      } catch {
        notify.danger('User could not be added to the role');
      }
    },
    async loadUsersForRole() {
      const users = await ApiService.get(`/org/${this.org.id}/role/${this.role.type}`);
      this.roleUsers = users.ids || [];
    },
  },
});
