

























































import Vue from 'vue';
import { OrgModel } from '@/types/org';
import { availableRoles, availablePermissions } from '@/components/admin/org/roleInformation';
import ApiService from '@/services/api.service';
import ManageUsersFromRole from '@/views/admin/org/roles/ManageUsersFromRole.vue';
import AppCard from '@/components/card/AppCard.vue';

export default Vue.extend({
  name: 'ListRoles',
  components: {
    AppCard,
    ManageUsersFromRole,
  },
  computed: {
    details(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    breadcrumbs(): any[] {
      return [
        {
          label: this.details.label,
          url: `/org/${this.details.id}`,
        },
        {
          label: 'List Roles',
        },
      ];
    },
  },
  data() {
    return {
      availableRoles: availableRoles.slice(1),
      availablePermissions,
      roles: [] as any[],
      columns: [
        {
          name: 'name',
          label: 'Roles Name',
          field: 'name',
          align: 'left',
        },
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          align: 'left',
        },
        {
          name: 'users',
          label: 'Users with Role',
          field: 'users',
          align: 'center',
        },
        {
          name: 'manage',
          label: 'Manage Users',
          field: 'manage',
          align: 'center',
        },
      ],
    };
  },
  methods: {
    async loadRolesInfo() {
      const rolesWithUsers = await ApiService.get(`/org/${this.details.id}/role`);
      this.roles = this.availableRoles.map((role: any) => {
        const existingUsers = rolesWithUsers.find((u: any) => u.role === role.type);
        role.users = existingUsers ? existingUsers.users : 0;
        return role;
      });
    },
  },
  async created() {
    await this.loadRolesInfo();
  },
});
